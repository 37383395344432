import { createI18n } from "vue-i18n"
import * as lang from './index'
import { getLocale } from '../functions'

//const { getLocale } = await import('../functions')

const messages: {[key: string]: {}} = {}

Object.entries(lang).forEach(l =>
{
	messages[l[0]] = l[1].strings
})

export const i18n = createI18n(
{
	useScope: 'global',
	pluralizationRules: {
		ru: ( choice: number, choicesLength: number ) =>
		{
			if( choice === 0 )
			{
				return 0
			}
		
			const teen = choice > 10 && choice < 20
			const endsWithOne = choice % 10 === 1

			if( !teen && endsWithOne )
			{
				return 1
			}

			if( !teen && choice % 10 >= 2 && choice % 10 <= 4 )
			{
				return 2
			}
		
			return choicesLength < 4 ? 2 : 3
		},
	},
	locale: getLocale(),
	fallbackLocale: 'en',
	messages,
})