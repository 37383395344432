// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { getAnalytics } from "firebase/analytics"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { connectStorageEmulator, getStorage } from "firebase/storage"
import { getMessaging } from "firebase/messaging"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig =
{
	apiKey:				import.meta.env.VITE_FB_APIKEY,
	authDomain:			import.meta.env.VITE_FB_AUTHDOMAIN,
	projectId:			import.meta.env.VITE_FB_PROJECTID,
	storageBucket:		import.meta.env.VITE_FB_STORAGEBUCKET,
	messagingSenderId:	import.meta.env.VITE_FB_MESSAGINGSENDINGID,
	appId:				import.meta.env.VITE_FB_APIID,
	measurementId:		import.meta.env.VITE_FB_MEASUREMENTID,
	vapidKey:			import.meta.env.VITE_FB_VAPIDKEY,
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const functions = getFunctions(app)
export const messaging = getMessaging(app)

// Local test
if( import.meta.env.DEV )
{
	connectFunctionsEmulator(functions, window.location.hostname, 5001)
	connectFirestoreEmulator(db, window.location.hostname, 8080)
	connectStorageEmulator(storage, window.location.hostname, 9199)
}